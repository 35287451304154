<template>
    <div class="search" v-loading="loading">
        <div class="search-inner">
            <div class="search-header">
                <search-input :search-text="keyword" />
            </div>
            <div class="search-filter">
                <div class="filter-result">
                    <div class="all-filter">
                        <span @click="handleClearSelectedFilterList">所有结果</span><i class="el-icon-arrow-right" />
                    </div>
                    <el-tag class="filter-content-text text-overflow" closable
                            :disable-transitions="true"
                            @close="handleFilterItemClose(selectItem)"
                            v-for="(selectItem, index) in selectedFilterList"
                            :key="index"
                    >
                        {{ selectItem.name }}
                    </el-tag>
                    <div class="search-num">
                        <span>"{{ showKeyword }}"</span>&nbsp;
                        <span>共{{ total }}个商品</span>&nbsp;&nbsp;
                        <el-button size="mini" plain
                                   @click="handleClearSelectedFilterList"
                                   v-if="selectedFilterList.length>0"
                        >
                            清空筛选
                        </el-button>
                    </div>
                </div>
                <div class="filter-list" v-if="firstNullFlag">
                    <div class="filter-list-inner">
                        <div class="filter-item">
                            <div class="filter-title text-overflow">考拉服务：</div>
                            <div class="filter-content">
                                <div class="filter-content-inner">
                                    <el-tag class="filter-content-text text-overflow"
                                            :color="kitem.checked ? '' : '#f5f5f5'"
                                            @click.native="handleFilterItemClick(kitem)"
                                            v-for="(kitem, kindex) in kaolaService"
                                            :key="kindex"
                                    >
                                        {{ kitem.name }}
                                    </el-tag>
                                </div>
                            </div>
                        </div>
                        <div class="filter-item" v-for="(item, index) in filterList" :key="index"
                             :style="{height:item.fold?'auto':'45px',overflow:item.fold?'auto':'hidden'}"
                        >
                            <div class="filter-title text-overflow">{{ item.title }}：</div>
                            <div class="filter-content">
                                <div class="filter-content-inner">
                                    <el-tag class="filter-content-text text-overflow"
                                            :color="fitem.checked ? '' : '#f5f5f5'"
                                            @click.native="handleFilterItemClick(fitem)"
                                            v-for="(fitem, findex) in item.list"
                                            :key="findex"
                                    >
                                        {{ fitem.name }}
                                    </el-tag>
                                </div>
                            </div>
                            <div class="filter-more"
                                 v-if="item.list.length > 8" @click="handleFoldStatus(item)"
                            >
                                <span v-if="!item.fold">展开<i class="el-icon-arrow-down" /></span>
                                <span v-if="!!item.fold">收起<i class="el-icon-arrow-up" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filter-sort" v-if="firstNullFlag">
                <sort-filter :type="2" @sort-change="handleSortTypeChange" />
            </div>
            <div class="search-result" v-if="goodsList && goodsList.length > 0">
                <goods v-for="item in goodsList" :key="item.goodsId"
                       :item="item"
                />
                <div class="wrap" v-for="n in 20" :key="n" />
            </div>


            <div class="search-result nodata"
                 v-if="(!goodsList || goodsList.length === 0) && !loading"
            >
                <no-data>
                    <div>
                        <p>抱歉，没有找到相关的商品，换个搜索词或减少筛选条件试试吧</p>
                    </div>
                </no-data>
            </div>


            <div class="search-page" v-if="goodsList && goodsList.length > 0">
                <pagination @size-change="handleSizeChange"
                            @current-change="handlePageChange"
                            :current-page="pageNo"
                            :page-size="pageSize"
                            :total="total"
                />
            </div>
        </div>

        <unfold-layer />
        <goods-dialog />
    </div>
</template>

<script>
import is from '@kaola/core.util/lib/tools/is';
import SearchInput from '../../components/search/search-input';
import SortFilter from '../../components/search/sort-filter';
import Goods from '../../components/goodsPromotion/goodsModel';
import UnfoldLayer from '../../components/goodsPromotion/unfoldLayer';
import Pagination from '../../components/common/pagination';
import noData from '../../components/common/noData.vue';
import goodsDialog from '../../components/goodsPromotion/goodsDialog.vue';

const baseUrl = process.env.NODE_ENV === 'production' ? '//zk.kaola.com/' : '//127.0.0.1:5193/';
const requestUrl = `${baseUrl}openapi/search/result`;

export default {
    data() {
        return {
            loading: true,

            isFirstSearch: true,

            keyword: '',
            showKeyword: '',

            // 搜索排序
            type: 0,
            isOrderDesc: false,
            kaolaService: [{
                id: 'isSelfProduct',
                name: '考拉自营',
                checked: false
            }, {
                id: 'isStock',
                name: '仅看有货',
                checked: false
            }, {
                id: 'isFactoryStore',
                name: '考拉工厂店',
                checked: false
            }],
            // 其他筛选
            filterList: [],
            // 搜索结果
            total: 0,
            goodsList: [],
            pageNo: 1,
            pageSize: 40,
            firstNullFlag: true
        };
    },

    components: {
        'search-input': SearchInput,
        'sort-filter': SortFilter,
        goods: Goods,
        'unfold-layer': UnfoldLayer,
        pagination: Pagination,
        noData,
        goodsDialog
    },

    watch: {
        $route: {
            /*eslint-disable*/
            handler(to) {
                const searchKey = to.query.key;
                if(!searchKey) {
                    this.$router.replace('/promotion/goods');
                    return;
                }

                this.resetSearch();
                this.clearSelectedFilterList();
                this.isFirstSearch = true;
                this.keyword = searchKey;
                this.showKeyword = this.keyword;
                if(this.showKeyword.length > 8) {
                    this.showKeyword = `${this.showKeyword.slice(0, 8)}...`;
                }
                this.startSearch();
            },
            /*eslint-disable*/
            immediate: true
        }
    },

    computed: {
        selectedFilterList() {
            const selectKaolaService = this.kaolaService.filter((item) => !!item.checked);
            let list = [];
            this.filterList.forEach((fitem) => {
                if(fitem.list && fitem.list.length) {
                    list = [...list, ...fitem.list.filter((item) => !!item.checked)];
                }
            });
            return [...selectKaolaService, ...list];
        },

        isSelfProduct() {
            return this.kaolaService[0].checked;
        },
        isStock() {
            return this.kaolaService[1].checked;
        },
        isFactoryStore() {
            return this.kaolaService[2].checked;
        }
    },
    methods: {
        // 重置除筛选外的搜索条件, 换关键词适用
        resetSearch() {
            this.type = 0;
            this.isOrderDesc = false;
            this.total = 0;
            this.goodsList = [];
            this.pageNo = 1;
        },
        /*eslint-disable*/
        async startSearch() {
            this.loading = true;
            this.goodsList = [];
            try {
                const data = await this.$request({
                    url: requestUrl,
                    method: 'post',
                    toast: false,
                    data: {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                        key: this.keyword,
                        spellCheck: false,
                        type: this.type,
                        isDesc: this.isOrderDesc,
                        clickSource: 10,
                        isStock: this.isStock,
                        isSelfProduct: this.isSelfProduct,
                        isFactoryStore: this.isFactoryStore,
                        lowerPrice: -1,
                        upperPrice: -1,
                        filterList: this.filterList
                    }
                });
                this.total = (data.pagination && data.pagination.maxRowCount) || 0;
                if(this.isFirstSearch) {
                    this.firstNullFlag = this.total !== 0;
                    this.filterList = (data.filterList || []).map((item) => {
                        item.fold = false;
                        return item;
                    });
                    this.isFirstSearch = false;
                }
                this.goodsList = data.goodsList || [];
            } finally {
                this.loading = false;
            }
        },
        /*eslint-disable*/
        handleSortTypeChange(v) {
            if(is('boolean')(v)) {
                this.type = 1;
                this.isOrderDesc = v;
            } else {
                this.type = v;
                this.isOrderDesc = false;
            }
            this.pageNo = 1;
            this.startSearch();
        },

        handleFilterItemClick(item) {
            item.checked = !item.checked;
            this.pageNo = 1;
            this.startSearch();
        },

        /* eslint-disable */
        handleFilterItemClose(item) {
            item.checked = false;
            let list;
            let fIndex;
            const ksIndex = this.kaolaService.findIndex((k) => {
                return k.id === item.id
            });
            if(ksIndex > -1) {
                this.kaolaService.splice(ksIndex, 1, item);
                this.pageNo = 1;
                this.startSearch();
                return;
            }

            for(let i = 0; i < this.filterList.length; i++) {
                if(this.filterList[i].list && this.filterList[i].length) {
                    list = this.filterList[i].list;
                    fIndex = list.findIndex((f) => {
                        return f.id === item.id;
                    });
                    if(fIndex > -1) {
                        list.splice(fIndex, 1, item);
                        this.filterList.splice(i, 1, list);
                        return;
                    }
                }
            }
            this.pageNo = 1;
            this.startSearch();
        },
        /* eslint-enable */

        handlePageChange(page) {
            this.pageNo = page;
            this.startSearch();
        },

        handleSizeChange(size) {
            this.pageNo = 1;
            this.pageSize = size;
            this.startSearch();
        },

        clearSelectedFilterList() {
            this.kaolaService.forEach((item) => {
                item.checked = false;
            });
            this.filterList.forEach((filterItem) => {
                (filterItem.list || []).forEach((item) => {
                    item.checked = false;
                });
            });
        },

        handleClearSelectedFilterList() {
            if(!this.selectedFilterList || !this.selectedFilterList.length) {
                return;
            }
            this.clearSelectedFilterList();
            this.startSearch();
        },

        handleFoldStatus(filterItem) {
            filterItem.fold = !filterItem.fold;
        }
    }
};
</script>

<style lang="scss" scoped>
.search {

    padding-bottom: 60px;
    min-height: 100%;

    &-inner {
        margin: 0 auto;
        width: 1140px;
    }
}


.search-header{
    background: #fff;
    padding-bottom: 20px;
}
.search-filter {
    background: #fff;
    padding: 0 0 20px;
}
.filter-result {
    padding-left: 20px;
    padding-right: 20px;
    word-break: break-all;
    .filter-content-text {
        margin-right: 10px;
        margin-bottom: 10px;
        vertical-align: middle;
    }
    .all-filter {
        height: 32px;
        line-height: 32px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: middle;
        span {
            cursor: pointer;
        }
    }
    .search-num {
        margin-bottom: 10px;
        height: 32px;
        line-height: 32px;
        display: inline-block;
        vertical-align: middle;
    }
}
.filter-list {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    max-height: 300px;
    overflow: auto;
    .filter-item {
        position: relative;
        font-size: 12px;
        margin-bottom: 10px;
        height: 45px;
        overflow: hidden;
        .filter-title {
            height: 32px;
            line-height: 32px;
            padding-right: 15px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            font-weight: 600;
        }
        .filter-more {
            height: 32px;
            line-height: 32px;
            text-align: center;
            position: absolute;
            right: 0;
            top: 0;
            width: 80px;
            cursor: pointer;
        }
        .filter-content {
            padding-left: 100px;
            padding-right: 80px;

            .filter-content-inner {
                width: 100%;
                .filter-content-text {
                    text-align: center;
                    width: 100px;
                    margin-left: 14px;
                    margin-bottom: 10px;
                    display: inline-block;
                    color: #333;
                    box-shadow: none;
                    border-color: #f5f5f5;
                    cursor: pointer;
                }
            }
        }
    }
}
.search-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -16px;
    margin-top: 20px;
    .wrap {
        width: 220px;
        margin-right: 16px;
    }
}
.nodata {
    position: relative;
    height: 200px;
}
.search-page {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 60px;
}
</style>

<template>
    <div class="search-input">
        <el-input placeholder="请输入内容" v-model="text">
            <template slot="append">
                <el-button type="primary" icon="el-icon-search"
                           @click="jump2Search"
                           class="button"
                >
                    搜索
                </el-button>
            </template>
        </el-input>
    </div>
</template>

<script>
export default {
    props: ['searchText'],

    data() {
        return {
            text: this.searchText || ''
        };
    },

    watch: {
        searchText(t) {
            this.text = t;
        }
    },

    methods: {
        jump2Search() {
            if(!this.text || !this.text.length) {
                this.$router.push('/promotion/goods');
                return;
            }
            this.$router.push(`/promotion/search?key=${this.text}&t=${+new Date()}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.search-input {
    width: 500px;
    margin: 0 auto;
    padding-top: 20px;
    .button{
        // background: #ff1e32;
        // color: #fff;
        // border-radius: 0 32px 32px 0;
        // border: 1px solid #ff1e32;
    }
}
</style>

<style lang='scss'>
.search-input {
    .el-input-group--append .el-input__inner {
        border: 2px solid #ff1e32;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
    }
    .el-input-group__append, .el-input-group__prepend {
        background-color: #ff1e32;
        color: #fff;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        border: 1px solid #ff1e32;
        border-radius: 0 32px 32px 0;
        padding: 0 20px;
        width: 1px;
        white-space: nowrap;
    }
}
</style>

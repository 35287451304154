<template>
    <div class="wp">
        <div class="sort-items">
            <p class="sort-item" v-for="(item, index) in items" :key="index"
               :class="{'active': activeIndex === index}"
               @click="handleItemClick(item, index)"
            >
                <span class="tag-name">{{ item.label }}</span>
                <i v-if="index !== 0" class="el-icon-bottom" />
            </p>
            <div class="sort-item" @click="handlePriceItemClick">
                <span class="tag-name" :class="{'active': activeIndex === 3}">价格</span>
                <span class="price-icon">
                    <span class="top" :class="{'active': !isOrderDesc && activeIndex === 3}">
                        <i class="el-icon-caret-top" />
                    </span>
                    <span class="bottom" :class="{'active': isOrderDesc && activeIndex === 3}">
                        <i class="el-icon-caret-bottom" />
                    </span>
                </span>
            </div>
        </div>
        <!-- <div class="mm" :class="{'active': bizType === 1}" @click="cg(1)">猫享自营</div> -->
        <div class="mm" :class="{'active': bizType === 0}" @click="cg(0)">考拉海购</div>
    </div>
</template>

<script>
// 1：综合排序   2：佣金比例  3：销量排序  4： 价格递增  5：价格递减
const sortItems1 = [{
    label: '综合',
    value: 1
}, {
    label: '佣金比例',
    value: 2
}, {
    label: '销量',
    value: 3
}];
// 1：综合排序   2：佣金比例  3：销量排序  4： 价格递增  5：价格递减
const sortItems2 = [{
    label: '综合',
    value: 0
}, {
    label: '佣金比例',
    value: 10
}, {
    label: '销量',
    value: 2
}];
export default {
    props: {
        type: {
            type: Number,
            default: 1 // 用于搜索2 还是 商品推广页1
        },
        bizType: {
            type: Number,
            default: 0 // 0 考拉， 1 猫享
        }
    },
    data() {
        return {
            activeIndex: 0,
            items: this.type === 1 ? sortItems1 : sortItems2,
            isOrderDesc: false
        };
    },

    methods: {
        cg(index) {
            if(index === this.bizType) {
                return;
            }

            this.$emit('biz-change', index);
        },
        handleItemClick(item, index) {
            if(this.activeIndex === index) {
                return;
            }
            this.activeIndex = index;
            this.$emit('sort-change', item.value);
        },

        handlePriceItemClick() {
            this.activeIndex = 3;
            this.isOrderDesc = !this.isOrderDesc;
            if(this.type === 2) {
                this.$emit('sort-change', this.isOrderDesc);
                return;
            }
            this.$emit('sort-change', this.isOrderDesc ? 5 : 4);
        }
    }
};
</script>

<style lang="scss" scoped>

.wp {
    display: flex;
    align-items: center;
}
.sort-items {
    margin-top: 20px;
    .sort-item {
        display: inline-block;
        margin-right: 24px;
        cursor: pointer;
        font-weight: bold;
        &.active {
            color: red;
        }
        .tag-name.active {
            color: red;
        }
        .price-icon {
            position: relative;
            .top {
                font-size: 18px;
                position: absolute;
                top: -6px;
                &.active {
                    color: red;
                }
            }
            .bottom {
                font-size: 18px;
                position: absolute;
                top: 0;
                &.active {
                    color: red;
                }
            }
        }

        .el-icon-caret-top, .el-icon-caret-bottom {
            font-size: 12px;
        }
    }
}

.mm {
    margin-top: 20px;
    height: 18px;
    width: 60px;
    border: 1px solid #e3e3e3;
    border-radius: 9px;
    background-color: #FFF8F8;
    line-height: 18px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    text-align: center;
    margin-left: 10px;
    &.active {
        color: #f00;
        border-color: #f00;
    }
}
</style>
